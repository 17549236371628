import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Image Upload component is used to display and upload images.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://teddys-minio-teddys-dev.vsan-apps.playcourt.id/legion/component/imageUpload/android_imageUpload.gif",
            "alt": "Legion Image Upload Android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Theme`}</h2>
    <h3>{`Image Upload Info`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldInfo"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:photoLimit="3" />

`}</code></pre>
    <h4>{`Kotlin Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.photoFieldInfo.info = "Info"
...
`}</code></pre>
    <h3>{`Image Upload Success`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldSuccess"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:photoLimit="3" />


`}</code></pre>
    <h4>{`Kotlin Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.photoFieldInfo.info = "Success"
...
`}</code></pre>
    <h3>{`Image Upload Error`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldError"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:photoLimit="3" />

`}</code></pre>
    <h4>{`Kotlin Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.photoFieldInfo.info = "Error"
...
`}</code></pre>
    <h3>{`Image Upload Helper`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldHelper"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:helperText="Helper"
  app:photoLimit="3" />


`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <h3>{`Image Upload with Hint and Helper`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldLabelHelper"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:hint="Label"
  app:helperText="Message"
  app:photoLimit="3" />

`}</code></pre>
    <h3>{`Image Upload only with hint`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldLabel"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:hint="Label"
  app:photoLimit="3" />

`}</code></pre>
    <h3>{`Image Upload only with Helper`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldHelper"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:helperText="Message"
  app:photoLimit="3" />


`}</code></pre>
    <h3>{`Image Upload without Hint and Helper`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldOnly"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:photoLimit="3" />

`}</code></pre>
    <div className="divi" />
    <h2>{`State`}</h2>
    <h3>{`Enable Image Upload`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldEnabled"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:enabled="true"
  app:photoLimit="3" />


`}</code></pre>
    <h3>{`Disable Image Upload`}</h3>
    <h4>{`XML Code`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnPhotoField
  android:id="@+id/photoFieldDisabled"
  android:layout_width="match_parent"
  android:layout_height="wrap_content"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:enabled="false"
  app:photoLimit="3" />

`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set hint on Image Upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set help button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:showHelpButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.showHelpButton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show help button when label visible on Image Upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set optional text on Image Upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set required text on Image Upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set helper text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set helper text on Image upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set photo limit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:photoLimit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set limit uploaded image on Image upload`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set enable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.isEnabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set enable on Image upload`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      